import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { history } from 'umi'

//兼容react-routerv4的中间件：https://github.com/supasate/connected-react-router
import { routerMiddleware } from 'connected-react-router/immutable'

import reducer from './reducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  // initialState,
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))),
)

// const persistor = persistStore(store)

export { history, store }

export const SAVE_PHONE_NUMBER = 'register/save_phone_number'
export const SET_PASSWORD_ERROR_TEXT = 'register/set_password_error_text' // 设置密码错误提示
export const CHANGE_CODE_STATUS = 'register/change_code_status' //改变验证码发送状态
export const CHANGE_COUNTDOWN = 'register/change_countdown' //改变countDown变量数值，用于验证码倒计时
export const SET_CODE_ERROR_TEXT = 'register/set_code_error_text' //设置验证码错误信息
export const SET_PHONE_NUMBER_ERROR_TEXT =
  'register/set_phone_number_error_text' //设置手机号错误信息
export const TOGGLE_GRAPHIC_DIALOG = 'register/toggle_graphic_dialog' //显示/隐藏图形验证码输入框
export const TOGGLE_SET_PASSWORD_DIALOG = 'register/toggle_setpassowrd_dialog' //显示/隐藏图形验证码输入框
export const SET_GRAPHIC_ERROR_TEXT = 'register/set_graphic_error_text' //设置图形验证码错误信息
export const SET_GRAPHIC_CAPTCHA_URL = 'register/set_graphic_captcha_url' //设置图形验证码图片的地址
export const TOGGLE_REGISTER_SUCCESS = 'register/toggle_register_success' //显示注册成功
export const SHOW_APPLY_INSTRUCTION = 'register/show_apply_instruction' //显示注册成功
export const SET_ACCESS_TOKEN = 'register/set_access_token'
export const SET_CAPTCHA = 'register/set_captcha'
export const TOGGLE_SECURITY_VERIFICATION = 'login/toggle_security_verification' // 切换安全组件显示
export const SET_SECURITY_VERIFICATION_TEMP_PARAMS =
  'login/set_security_verification_temp_params' // 保存调起安全组件操作的临时参数

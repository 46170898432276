import qs from 'qs'
import url from 'url'
import _ from 'lodash'
import { helpers } from '@common'
const { getRuntimeEnv } = helpers

const defaultOptions = {
  key: 'label',
  value: 'value',
}

/**
 * 把数据源格式化为 ant mobile picker 里适用的数据
 * eg:
 *
 * ['a', 'b'] => [{label: 'a', value: 'a'}, {label: 'b', value: 'b'}]
 *
 * [{2: "Karyawan"}, {4: "Wirausaha"}] => [{label: 'Karyawan', value: 2}, {label: 'Wirausaha', value: 4}]
 *
 * [{id: 2, name: "Karyawan"}, {id: 4, name: "Wirausaha"}] => [{label: 'Karyawan', value: 2}, {label: 'Wirausaha', value: 4}]
 *
 * @param {Array}   data            数据源
 * @param {Boolean} onlyChangeKey   是否只转换 key 的名字
 * @param {Boolean} isObject        元素类型，是否为 object
 * @param {Object}  options         设置元素的 key 名字
 */
export const array2PickerData = ({
  data,
  onlyChangeKey: onlyChangeKey = false,
  isObject: isObject = false,
  options: options = defaultOptions,
}) => {
  const result = []
  data.forEach((e) => {
    result.push(
      !onlyChangeKey
        ? {
            [options.key]: isObject ? Object.values(e)[0] : e,
            [options.value]: isObject ? +Object.keys(e)[0] : e,
          }
        : {
            [options.key]: e.name,
            [options.value]: e.id,
          },
    )
  })
  return result
}

/**
 * 返回展开后的数组
 * @param {Array} arr 需要展开的数组
 */
export const flattenArr = (arr) => {
  let entries = []

  arr.authInfo.forEach((item) => {
    entries.push(item.entries)
  })
  entries = _.flatten(entries)

  return entries
}

/**
 * 获取大写首字母
 * @param {String} str 需要获取的字符串
 */
export const getInitials = (str) => str.toUpperCase().charAt(0)

/**
 * 根据首字母格式化
 * @param {Array}   data 数组对象
 * @param {String}  key 根据 key 字段来分组   default: label
 */
export const accordingInitialsFormat = (data, key = 'label') => {
  const result = {}

  data.sort((a, b) => {
    return a[key].charCodeAt(0) - b[key].charCodeAt(0)
  })

  data.forEach((e) => {
    const char = getInitials(e[key])
    result[char] = result[char] || []
    result[char].push(e)
  })

  return result
}

/**
 * 给 url query 添加参数，并且返回添加后的 query 串
 * @param {String} targetUrl url 地址
 * @param {Object} addQueryObj 需要添加的参数
 */
export const urlAddQuery = (targetUrl, addQueryObj) => {
  const { query } = url.parse(targetUrl)
  const parseQuery = qs.parse(query)

  return `?${qs.stringify({ ...parseQuery, ...addQueryObj })}`
}

/**
 * 给 url query 添加参数，并且返回添加后的url，存在的key进行修改，没有的进行添加
 * example:
 *  1. addQueryToUrl('http://www.baidu.com?name=old&age=18',{name:'lisi'})
 *     ↓↓↓↓↓↓
 *     http://www.baidu.com?name=lisi&age=18
 *  2. addQueryToUrl('?name=old&age=18',{name:'lisi'})
 *     ↓↓↓↓↓↓
 *     ?name=lisi&age=18
 * @param {string} targetUrl url 地址
 * @param {Object} query 需要添加的参数
 * @returns {string}
 */
export function addQueryToUrl(targetUrl, query) {
  const t = url.parse(targetUrl)
  const oldQuery = qs.parse(t.query)
  t.search = qs.stringify({ ...oldQuery, ...query }, { format: 'RFC1738' })
  return url.format(t)
}
/**
 * 给 url query 删除参数，并且返回删除后的 query 串
 * @param {String} targetUrl url 地址
 * @param {String} deleteKey 需要删除的 key
 */
export const urlDeleteQuery = (targetUrl, deleteKey) => {
  const { query } = url.parse(targetUrl)
  const parseQuery = qs.parse(query)

  delete parseQuery[deleteKey]

  return `?${qs.stringify({ ...parseQuery })}`
}

/**
 * 20201203 新系统切量期间 所有需要退到登录页的都要退回到老系统登录页
 * **/
export const logoutToOldSys = () => {
  const env = getRuntimeEnv()

  const domain =
    env === 'test'
      ? 'https://test-mall.akulaku.com/'
      : 'https://mall.akulaku.com/'
  const path = window.location.href.includes('/pc/')
    ? 'open-pay/pc/signin'
    : 'open-pay/signin'
  let search = window.location.search
  if (search) {
    search += '&jumpFromNew=true'
  } else {
    search = '?jumpFromNew=true'
  }
  window.location.href = `${domain}${path}${search}`
}

/**
 * 给安全组件使用的方式 获取请求的baseUrl
 * */

export const getApiBaseUrl = (prefix) => {
  const apiPrefix = prefix || ''
  const env = getRuntimeEnv()
  // localhost
  if (
    window.location.hostname &&
    window.location.hostname.indexOf('localhost') > -1
  ) {
    return apiPrefix
  }
  if (env === 'test') {
    return `https://test-pay.akulaku.com${apiPrefix}`
  } else {
    return `https://ec-api.akulaku.com${apiPrefix}`
  }
}

// 手机号脱敏
export const phoneDesensitization = (phone) => {
  return phone ? `${phone.slice(0, 2)} **** ${phone.slice(-4)}` : phone
}

/** 检查域名，测试环境把mall域替换成换成pay */
export const replaceHost = (host) => {
  const env = getRuntimeEnv()
  if (env === 'local' || env === 'test') {
    return 'test-pay.akulaku.com'
  } else {
    return host
  }
}

/**
 * 测试环境替换接口域名
 **/
export const getApiHost = (originHost) => {
  const env = getRuntimeEnv()
  if (env === 'local' || env === 'test') {
    return 'test-pay.akulaku.com'
  } else {
    return originHost || 'id-app.akulaku.com'
  }
}

import axios from 'axios'
import JsCookie from 'js-cookie'
// import { EventTracking } from '@al/event-tracking'
// import { LocalTimeService } from '@al/base-lib'
import { helpers } from '@common'
// import ALDeviceSDK from '@al/device-data-sdk'
import BridgeV2 from '@akulaku/web-bridge'

const ENV = helpers.getRuntimeEnv()
import { getDeviceSDK, initDeviceSDK } from '@/components/device-sdk'

const userId = helpers.URL.getParam('uid')

if (userId) {
  helpers.storage.setSession('openpay_uid', userId)
}

// export const tracker = new EventTracking()

/**
 * 可能会返回 undefined，需要做容错处理
 */
export async function initTracker() {
  await initDeviceSDK()
  const ALDeviceSDK = await getDeviceSDK()
  // 订单列表页不需要初始化埋点插件
  if (location.pathname === '/open-pay/order-list') return
  const { createTracker } = await import('@al/event-tracking')
  const { LocalTimeService } = await import('@al/base-lib')
  const tracker = createTracker()
  // smDeviceId数美设备id每次刷新都会更新
  const smDeviceId = await ALDeviceSDK.getSmDeviceId()
  const deviceId = await ALDeviceSDK.getDeviceId()
  tracker.init({
    storeName: 'openpay',
    common: {
      // 运行环境
      env: ENV,
      countryId: JsCookie.get('countryId'),
      countryCode: JsCookie.get('countryCode'),
      userId: helpers.storage.getSession('openpay_uid'),
      routeHooks: {
        // 离开
        leave: () => {},
        // 进入
        enter: () => {},
      },
      // 对钟逻辑
      localTimeService: () => {
        return new LocalTimeService(() => {
          // 对钟逻辑
          return new Promise((resolve, reject) => {
            const url = `https://${
              ENV === 'prod' ? '' : 'test-'
            }ec-api.akulaku.com/aapi/time/now`
            axios
              .get(url)
              .then((res) => {
                if (res.data.success) {
                  resolve({
                    sysTime: res.data.sysTime,
                  })
                } else {
                  reject()
                }
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    },
    behavior: {
      v4: {
        deviceId,
        smDeviceId,
        appName: 'akulaku',
        isNative: false,
        appBrand: 'op',
      },
    },
    sa: {
      disableVisibilityHandler: true,
      webUrl:
        ENV === 'prod'
          ? 'https://sc-new.akulaku.com/?project=production'
          : 'https://sc-new.akulaku.com/',
      showLog: process.env.BUILD_ENV === 'local',
      enabled: true,
      autoTrack: false,
      appName: 'akulaku',
      sessionIdKey: 'self_session_id',
      isNative: BridgeV2.isAkulaku(),
      prefix: 'Aku',
      nativeLogHandler: (data) => {
        return new Promise((resolve, reject) => {
          if (BridgeV2.reportSence) {
            BridgeV2.reportSence(data)
            resolve(undefined)
          } else {
            reject()
          }
        })
      },
    },
  })

  window.tracker = tracker
  return tracker
}

export const getTracker = async () => {
  if (window.tracker) {
    return window.tracker
  } else {
    return await initTracker()
  }
}

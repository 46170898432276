import { fromJS } from 'immutable'
import * as actions from './actions'

const defaultState = fromJS({
  couponList: [
    {
      couponType: 1,
      faceAmount: 500000000000000000000,
      timeLimitType: 1,
      startDate: 22,
      endDate: 33,
      name: 'this is a xxx',
      id: 1,
    },
    {
      couponType: 2,
      faceAmount: 50000,
      timeLimitType: 1,
      startDate: 22,
      endDate: 33,
      name: 'this is a xxx',
      id: 2,
    },
    {
      couponType: 3,
      faceAmount: 50000,
      timeLimitType: 1,
      startDate: 22,
      endDate: 33,
      name: 'this is a xxx',
      id: 3,
    },
    {
      couponType: 4,
      faceAmount: 50000,
      timeLimitType: 1,
      startDate: 22,
      endDate: 33,
      name: 'this is a xxx',
      id: 4,
    },
    {
      couponType: 5,
      faceAmount: 50000,
      timeLimitType: 1,
      startDate: 22,
      endDate: 33,
      name: 'this is a xxx',
      id: 5,
    },
  ],
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_COUPON_LIST:
      return state.set('couponList', action.couponList)

    default:
      return state
  }
}

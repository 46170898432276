import { fromJS } from 'immutable'
import * as actions from './actions'
import { FIRST_PAGE_TYPE, FIRST_PAGE_LOGIN_TYPE } from '@pc/common/macro'

const defaultState = fromJS({
  curPeriod: '', //当前分期数
  pcData: {
    nowOperationType: 'login',
  },
  needLogoutToast: false, // 下单页安全组件登录态失效返回需要toast提示
  currentPage: FIRST_PAGE_TYPE.LOGIN, // 页面的展示类型
  loginType: FIRST_PAGE_LOGIN_TYPE.SMS, //  登录页展示的模块 sms account
  showInstallmentPlan: false, // 是否是首页分期优化的灰度发布
  bannerList: [], // banner图信息
  adTagContent: null, // 广告标签内容
  payAmount: '', // 订单金额
  installmentList: [], // 订单分期列表
  installmentVisible: false, // 分期弹窗显示
  showSetPasswordDialog: false,
  defaultPhoneNumber: '', //默认带入的手机号 用户输入后变成用户输入的手机号
  validPhoneNumber: '', // 用户输入的手机号 这里这个两个参数含义其实是有点乱的
  securityVerificationVisible: false, // 是否展示安全组件
  securityVerificationCaller: '', // 调用安全组件的操作
  securityVerificationTempParams: {}, // 安全组件调起时前置操作存储的临时参数，在每次关闭组件时清空，调起时保存
  operationId: '', // 安全组件的值
  sendSMSCode: false, // 是否已经发送了短信验证码 这是是给短信登录使用的，用于给倒计时来用的
  agreeBindProtocol: true, // 是否同意绑定协议 默认同意
  showBindProtocol: false, // 是否显示绑定协议
  canModifyPhoneNumber: true, // 是否可以修改登录号码 默认为true 表示可以修改
  showPostTokenProtocol: false, // 是否展示后置token绑定协议
})

export default (state = defaultState, action) => {
  switch (action.type) {
    //设置密码错误信息
    case actions.SET_PASSWORD_ERROR_TEXT:
      return state.set('pwdErrorText', action.errText)

    //设置密码错误信息
    case actions.SET_PHONE_NUMBER_ERROR_TEXT:
      return state.set('phoneNumberErrorText', action.errText)

    //设置当前是登录还是注册
    case actions.SET_NOW_OPREATION_TYPE:
      return state.merge({
        pcData: {
          nowOperationType: action.operationType,
        },
      })

    //将订单详情添加到state中
    case actions.ADD_STATE_FROM_ORDER:
      return state.merge({ ...action.orderDetail })

    //选择分期数
    case actions.SET_CUR_PERIOD:
      return state.merge({
        curPeriod: action.curPeriod,
      })

    // 设置banner信息
    case actions.SET_FIRST_PAGE_BANNER:
      return state.set('bannerList', action.bannerList)

    // 从下单页401导致跳转回来的安全组件提示
    case actions.SET_NEED_LOGOUT_TOAST:
      return state.set('needLogoutToast', action.needLogoutToast)

    // 设置当前页
    case actions.SET_CURRENT_PAGE:
      return state.set('currentPage', action.currentPage)

    // 设置是否展示分期信息
    case actions.SET_SHOW_INSTALLMENT_PLAN:
      return state.set('showInstallmentPlan', action.showInstallmentPlan)
    // 分期弹窗
    case actions.SET_INSTALLMENT_VISIBLE:
      return state.set('installmentVisible', !!action.installmentVisible)

    // 广告标签
    case actions.SET_AD_TAG_CONTENT:
      return state.set('adTagContent', action.adTagContent)

    // 设置分期列表
    case actions.SET_INSTALLMENT_LIST:
      return state.set('installmentList', action.installmentList)

    // 设置订单金额
    case actions.SET_PAY_AMOUNT:
      return state.set('payAmount', action.payAmount)

    // 设置登录类型 sms password
    case actions.SET_LOGIN_TYPE:
      return state.set('loginType', action.loginType)

    // 设置手机号码
    case actions.SET_DEFAULT_PHONE_NUMBER:
      return state.set('defaultPhoneNumber', action.defaultPhoneNumber)

    // 给忘记密码用的
    case actions.SET_CUR_VALID_PHONE_NUMBER:
      return state.set('validPhoneNumber', action.validPhoneNumber)

    //设置验证码错误信息
    case actions.SET_CODE_ERROR_TEXT:
      return state.set('codeErrorText', action.errText)

    case actions.TOGGLE_SET_PASSWORD_DIALOG:
      return state.set('showSetPasswordDialog', action.show)

    // 设置调起安全组件的方式
    case actions.SET_SECURITY_VERIFICATION_CALLER:
      return state.set(
        'securityVerificationCaller',
        action.securityVerificationCaller,
      )

    // 设置调起安全组件的方式
    case actions.TOGGLE_SECURITY_VERIFICATION:
      return state.set(
        'securityVerificationVisible',
        action.securityVerificationVisible,
      )

    // 设置调起安全组件的前置保存参数
    case actions.SET_SECURITY_VERIFICATION_TEMP_PARAMS:
      return state.merge({
        securityVerificationTempParams: {
          ...action.securityVerificationTempParams,
        },
      })

    // 设置调起安全组件的前置保存参数
    case actions.SET_OPERATION_ID:
      return state.set('operationId', action.operationId)

    // 发送短信验证码成功之后修改
    case actions.SET_SEND_SMS_CODE:
      return state.set('sendSMSCode', action.sendSMSCode)

    // 设置短信注册的accessToken
    case actions.SET_ACCESS_TOKEN:
      return state.set('accessToken', action.accessToken)

    case actions.SET_AGREE_BIND_PROTOCOL:
      return state.set('agreeBindProtocol', action.agreeBindProtocol)

    // 设置登录页是否要显示绑定协议
    case actions.SET_SHOW_BIND_PROTOCOL:
      return state.set('showBindProtocol', action.showBindProtocol)

    // 设置登录页是否要显示绑定协议
    case actions.SET_CAN_MODIFY_PHONE_NUMBER:
      return state.set('canModifyPhoneNumber', action.canModifyPhoneNumber)

    // 设置是否显示后置token绑定协议
    case actions.SET_SHOW_POST_TOKEN_PROTOCAL: {
      return state.set('showPostTokenProtocol', action.showPostTokenProtocol)
    }

    default:
      return state
  }
}

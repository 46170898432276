export const ADD_METHOD_INFO = 'paymentMethod/add_method_info' //拉取支付方式
export const RECORD_CURRENT_METHOD = 'paymentMethod/record_current_method' //记录当前选择的支付方式
export const CHANGE_CVV_NUMBER = 'paymentMethod/change_cv_number' //修改cvv号码
export const CHANGE_CARD_NUMBER = 'paymentMethod/change_card_number' //修改信用卡号
export const CHANGE_EXPIRE_YEAR = 'paymentMethod/change_expire_year' //修改到期年份
export const CHANGE_EXPIRE_MONTH = 'paymentMethod/change_expire_month' //修改到期月份
export const CHANGE_CREDIT_SUBMIT_STATUS =
  'paymentMethod/change_credit_submit_status' //改变信用卡信息提交状态
export const CHANGE_CREDIT_CARD = 'paymentMethod/change_credit_card' //切换信用卡
export const ADD_STORE_BANCKTRANSFER_INFO =
  'paymentMethod/add_store_backtransfer_info' //添加便利店或者银行转账信息
export const TOGGLE_PAYMENT_GUIDE = 'paymentMethod/toggle_payment_guide' //显示隐藏付款指引
export const ADD_MIDTRANS_URL = 'paymentMethod/add_midtrans_url' //添加midtrans支付网关的url
export const REMOVE_MIDTRANS_URL = 'paymentMethod/remove_midtrans_url' //移除midtrans支付网关的url
export const SET_CARD_NUMBER_ERROR_TEXT =
  'paymentMethod/set_card_number_error_text'
export const SET_CARD_MM_ERROR_TEXT = 'paymentMethod/set_card_mm_error_text'
export const SET_CARD_YY_ERROR_TEXT = 'paymentMethod/set_card_yy_error_text'
export const SET_CARD_CVV_ERROR_TEXT = 'paymentMethod/set_card_cvv_error_text'

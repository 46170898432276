import { fromJS } from 'immutable'
import * as actions from './actions'

const defaultState = fromJS({
  installmentInfo: {}, //分期信息
  payInfo: {}, //支付网关信息
})

export default (state = defaultState, action) => {
  switch (action.type) {
    //设置分期信息
    case actions.SET_INSTALLMENT_INFO:
      return state.merge({
        installmentInfo: action.installmentInfo,
        callbackPageUrl: action.callbackPageUrl,
      })

    case actions.SET_PAY_INFO:
      return state.merge({ payInfo: action.payInfo })

    default:
      return state
  }
}

import { helpers } from '@common'
import { store } from '@pc/store'
import axios from 'axios'
import { history } from 'umi'
import { logoutToOldSys } from '@pc/config/utils'
import { getDeviceSDK } from '@/components/device-sdk'

const { storage } = helpers

export const globalActions = {
  TOGGLE_GLOBAL_TOAST: 'global/toggle_global_toast', //显示、隐藏全局的toast
  TOGGLE_GLOBAL_LOADING: 'global/toggle_global_loading', //显示、隐藏全局的loading
  TOGGLE_GLOBAL_ALERT_DIALOG: 'global/toggle_global_alert_dialog', //显示全局警告弹窗
  CLEAR_ORDER_DETAIL_DATA: 'global/clear_order_detail_data', //清除订单详情页数据（如果有）
  ASYNC_GET_DEVICE_DATA: 'global/async_get_device_data', // 异步获取设备信息
  ASYNC_GET_GLOBAL_CONFIG: 'global/async_get_global_config', // 异步获取配置中心的全局配置
}

export const globalActionCreator = {
  toggleLoading: (show, content) => ({
    type: globalActions.TOGGLE_GLOBAL_LOADING,
    show,
    content,
  }),
  toggleToast: (show, content, duration) => ({
    type: globalActions.TOGGLE_GLOBAL_TOAST,
    show,
    content,
    duration,
  }),
  toggleAlertDialog: (show, { title, content, footer, closable }) => ({
    type: globalActions.TOGGLE_GLOBAL_ALERT_DIALOG,
    show,
    title,
    content,
    footer,
    closable,
  }),
  globalClearOrderDetailData: () => ({
    type: globalActions.CLEAR_ORDER_DETAIL_DATA,
  }),

  /**
   * 获取设备指纹
   * 为确保设备id的稳定性，需要在每个需要上报的环节都重新获取。
   * @param {Object} params 业务扩展参数，如页面、场景id等
   */
  globalAsyncGetDeviceData: (params = {}) => {
    return async (dispatch) => {
      // const ALDeviceSDK = (await import('@al/device-data-sdk')).default
      // const RUNTIME_ENV = helpers.getRuntimeEnv()
      // ALDeviceSDK.init({
      //   reportDevelopEnv: RUNTIME_ENV !== 'prod',
      //   reportAfterInited: false,
      //   reportThirdHost: true,
      //   extra: params,
      // })
      const ALDeviceSDK = await getDeviceSDK()
      const deviceData = await ALDeviceSDK.getDeviceData()
      // console.log('deviceData===>', deviceData)

      deviceData && storage.setSession('deviceData', deviceData)
      dispatch(__setDeviceData(deviceData))
      return deviceData
    }
  },

  // 上报设备指纹
  globalSendDeviceData: (params = {}) => {
    return async () => {
      const ALDeviceSDK = await getDeviceSDK()
      const state = store.getState()
      const countryId = state.getIn(['main', 'countryId'])
      let userInfo = helpers.storage.get('akulaku:userInfo')
      if (!userInfo) {
        // 重构系统暂无获取userinfo的接口，暂时从下单接口中获取
        const uid = state.getIn(['orderDetail', 'uid'])
        userInfo = { uid }
      }

      if (!userInfo.uid) {
        userInfo.uid = helpers.storage.getSession('openpay_uid')
      }

      Object.assign(params, {
        user_id: userInfo.uid ? userInfo.uid : '0', // 0为未登录
        app_brand: 'op',
        country_id: countryId,
      })

      ALDeviceSDK.upload({
        extra: params,
      })
    }
  },
  globalLogOut() {
    return async (dispatch) => {
      dispatch(globalActionCreator.toggleLoading(true))
      const { data } = await axios.post('/capi/openpay/v2/client/user/logout')
      dispatch(globalActionCreator.toggleLoading(false))

      if (data.success) {
        dispatch(globalActionCreator.smartJumpToLoginPage())
      }
      return data
    }
  },
  /**
   * 智能的跳转到登录页面
   * 从老系统跳转到新系统的用户，退出时需要跳转回去老系统
   * 在新系统登录的用户，退出时还是留在新系统
   */
  smartJumpToLoginPage() {
    return (dispatch, getState) => {
      const jumpFromOld = getState().getIn(['main', 'jumpFromOld'])
      if (jumpFromOld) {
        logoutToOldSys()
        return
      }
      history.replace({
        pathname: `./signin`,
        search: window.location.search,
      })
    }
  },
  /**
   * 从app配置中心拉取openpay配置信息
   * @returns 配置信息
   */
  globalAsyncGetConfig: () => {
    const openpayBid = 1017
    return async (dispatch) => {
      const params = {
        bidPairList: [
          {
            bid: openpayBid,
            bidVersion: 1,
          },
        ],
        platform: 3,
        appVersion: 0,
        regionId: 1,
        appId: 1,
      }
      const {
        data: { data, success },
      } = await axios.post(
        '/capi/app-config-service/public/list-config-info',
        params,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      if (success && data) {
        // 配置中心的配置一定是json格式
        const config = JSON.parse(
          data.find((item) => item.bid === openpayBid).content,
        )
        dispatch(__setGlobalConfig(config))
        return config
      } else {
        return {}
      }
    }
  },
  /**
   * 获取埋点上报所需数据
   * http://testyapi.akulaku.com/project/1212/interface/api/238266
   */
  globalGetReportConfig: (methodToken) => {
    return async (dispatch) => {
      const {
        data: { data },
        success,
      } = await axios.get(
        `/capi/openpay/public/config/report${
          methodToken ? `?methodToken=${methodToken}` : ''
        }`,
      )
      return { data, success }
    }
  },
}

const __setDeviceData = (data) => ({
  type: globalActions.ASYNC_GET_DEVICE_DATA,
  data,
})

const __setGlobalConfig = (data) => ({
  type: globalActions.ASYNC_GET_GLOBAL_CONFIG,
  data,
})

export const ADD_STATE_FROM_ORDER = 'login/add_state_from_order' //将订单数据添加到state中
export const CHANGE_CODE_STATUS = 'login/change_code_status' //改变验证码发送状态
export const CHANGE_COUNTDOWN = 'login/change_countdown' //改变countDown变量数值，用于验证码倒计时
export const CHOOSE_PERIOD = 'login/choose_period' //选择分期数
export const SHOW_PLAN = 'login/show_plan' //显示分期详情
export const TOGGLE_PASSWORD_DIALOG = 'login/toggle_password_dialog' //显示/隐藏密码输入框
export const TOGGLE_PLAN_DETAILS = 'login/toggle_plan_details' //显示/隐藏分期详情
export const ADD_PHONE_NUMBER_TO_STATE = 'login/add_phone_number_to_state' //显示密码输入框
export const SET_CODE_ERROR_TEXT = 'login/set_code_error_text' //设置验证码需哦呜信息
export const SET_PASSWORD_ERROR_TEXT = 'login/set_password_error_text' //设置密码错误信息
export const SET_PHONE_NUMBER_ERROR_TEXT = 'login/set_phone_number_error_text' //设置手机号错误信息
export const SET_DOWNPAYMENT_RATE = 'login/set_down_payment_rate' //设置首付比例
export const TOGGLE_GRAPHIC_DIALOG = 'login/toggle_graphic_dialog' //显示/隐藏图形验证码输入框
export const SET_GRAPHIC_ERROR_TEXT = 'login/set_graphic_error_text' //设置图形验证码错误信息
export const SET_GRAPHIC_CAPTCHA_URL = 'login/set_graphic_captcha_url' //设置图形验证码图片的地址
export const SET_CUR_PERIOD = 'login/set_cur_period' //设置当前选择的分期数
export const SET_NOW_OPREATION_TYPE = 'login/set_now_operation_type' //设置当前是登陆还是注册
export const SET_FIRST_PAGE_BANNER = 'login/set_first_page_banner' // 设置首页banner图
export const SET_NEED_LOGOUT_TOAST = 'login/set_need_logout_toast'
export const SET_SHOW_INSTALLMENT_PLAN = 'login/set_show_installment_plan' // 是否展示分期计划
export const SET_INSTALLMENT_VISIBLE = 'login/set_installment_visible' // 设置分期弹窗visible
export const SET_AD_TAG_CONTENT = 'login/set_ad_tag_content' // 设置广告标签
export const CHOOSE_CUR_PERIOD = 'login/choose_cur_period' // 选择分期弹窗的某个分期
export const SET_INSTALLMENT_LIST = 'login/set_installment_list' // 设置分期信息
export const SET_PAY_AMOUNT = 'login/set_pay_amount' // 设置订单金额
export const SET_CURRENT_PAGE = 'login/set_current_page' // 设置当前页面模块
export const SET_LOGIN_TYPE = 'login/set_login_type' // 设置登录类型
export const TOGGLE_SET_PASSWORD_DIALOG = 'login/toggle_set_password_dialog'
export const SET_DEFAULT_PHONE_NUMBER = 'login/set_default_phone_number' // 设置默认手机号
export const SET_CUR_VALID_PHONE_NUMBER = 'login/set_cur_valid_phone_number' // 设置用户输入有效手机号
export const TOGGLE_SECURITY_VERIFICATION = 'login/toggle_security_verification' // 切换安全组件显示
export const SET_SECURITY_VERIFICATION_CALLER =
  'login/set_security_verification_caller' // 设置安全组件由谁调起
export const SET_SECURITY_VERIFICATION_TEMP_PARAMS =
  'login/set_security_verification_temp_params' // 保存调起安全组件操作的临时参数
export const SET_OPERATION_ID = 'login/set_operation_id' // 设置operationId
export const SET_SEND_SMS_CODE = 'login/set_send_sms_code' // 设置是否发送了短信验证码
export const SET_ACCESS_TOKEN = 'login/set_access_token' // 短信登录下一步设置accessToken
export const SET_AGREE_BIND_PROTOCOL = 'login/set_agree_bind_protocol' // 设置是否同意绑定协议
export const SET_SHOW_BIND_PROTOCOL = 'login/set_show_bind_protocol' // 设置是否显示绑定协议
export const SET_CAN_MODIFY_PHONE_NUMBER = 'login/set_can_modify_phone_number' // 设置是否可以修改登录号码
export const SET_SHOW_POST_TOKEN_PROTOCAL = 'login/set_show_postToken_protocol' // 设置是否显示后置token绑定协议标志

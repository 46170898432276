import * as actions from './actions'
import { globalActions } from '../../../common/globalActions'
import { fromJS } from 'immutable'

const defaultState = fromJS({
  appId: '', //应用ID
  refNo: '', //订单号
  sign: '', //签名
  fingerprint: '', //浏览器签名
  countryId: 1,
  fromShopee: false,
  isSignValid: false, //登录态是否有效，有效则触发快捷登录
  isWhiteList: true, //该商户是否在白名单，在白名单则用新流程
  phoneNumber: '',
  loading: {
    show: false,
    content: '',
  },
  toast: {
    show: false,
    content: '',
    duration: 3,
  },
  alertDialog: {
    show: false,
    title: '',
    content: '',
    footer: '',
  },
  jumpFromOld: false, // 是否是从老系统通过uid切量过来的
  deviceData: {
    device_id: 'unknown',
    uuid: 'unknown',
  }, // 设备信息
  config: {}, // 全局配置
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case globalActions.ASYNC_GET_GLOBAL_CONFIG:
      return state.merge({ config: action.data })

    case globalActions.ASYNC_GET_DEVICE_DATA:
      return state.merge({ deviceData: action.data })

    case actions.SET_USER_LOGIN_STATUS:
      return state.set('isSignValid', action.isSignValid)

    case actions.SET_USER_PHONE_NUMBER:
      return state.set('phoneNumber', action.phoneNumber)

    case actions.SET_IF_IS_WHITELIST:
      return state.set('isWhiteList', action.isWhiteList)

    case actions.ADD_BASIC_ORDER_INFO:
      return state.merge({ ...action.basicState })

    case actions.ADD_FINGERPRINT:
      return state.set('fingerprint', action.fingerprint)

    case globalActions.TOGGLE_GLOBAL_LOADING:
      return state.merge({
        loading: {
          show: action.show,
          content: action.content,
        },
      })

    case globalActions.TOGGLE_GLOBAL_ALERT_DIALOG:
      return state.merge({
        alertDialog: {
          show: action.show,
          title: action.title,
          content: action.content,
          footer: action.footer,
          closable: action.closable,
        },
      })

    case globalActions.TOGGLE_GLOBAL_TOAST:
      return state.merge({
        toast: {
          show: action.show,
          content: action.content,
          duration: action.duration,
        },
      })

    case actions.SET_JUMP_FROM_OLD:
      return state.set('jumpFromOld', action.jumpFromOld)

    default:
      break
  }
  return state
}

export default {
  /** 支付成功页 */
  page_view: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
  },
  /** 返回商户 */
  back_to_merchant: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_element_id: 'openpay040102',
    Aku_element_name: 'return',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'result',
    Aku_position_id: '02',
  },
  /** 刷新按钮 */
  refresh: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_element_id: 'openpay040103',
    Aku_element_name: 'refresh',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'result',
    Aku_position_id: '03',
  },
  /** 重复支付 */
  reSuccess: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_element_id: 'openpay040104',
    Aku_element_name: 'pay success',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'result',
    Aku_position_id: '04',
  },
  /** 已关单 */
  order_close: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_element_id: 'openpay040105',
    Aku_element_name: 'close',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'result',
    Aku_position_id: '05',
  },
  banner_view_and_click: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_element_id: 'openpay040106',
    Aku_element_name: 'banner',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'result',
    Aku_position_id: '06',
  },
  // banner积分位点击
  point_banner_view_and_click: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_element_id: 'openpay040107',
    Aku_element_name: 'point banner',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'result',
    Aku_position_id: '07',
  },
  // 积分弹窗曝光
  point_modal_view: {
    Aku_page_id: 'openpay04',
    Aku_page_name: 'openpay payment result page',
    Aku_pop_id: 'pop90002',
    Aku_pop_name: 'point',
  },
}

import { fromJS } from 'immutable'
import * as actions from './actions'

const defaultState = fromJS({
  isMaster: true,
  isVisa: false,
  midtransUrl: '', //midtrans网关唤起的支付页面iframe的url
  methodInfo: {},
  curMethodInfo: {}, //当前选择支付方式的信息
  creditCardInfo: {
    //信用卡信息
    cardNumber: '',
    expireMonth: 'MM',
    expireYear: 'YY',
    cvvNumber: 'CODE',
    hasSubmitted: false, //信用卡信息成功提交标志
  },
  storeOrBankTransferInfo: {
    //便利店支付或转账信息
    showPaymentGuide: false,
  },
})

export default (state = defaultState, action) => {
  switch (action.type) {
    //拉取支付方式
    case actions.ADD_METHOD_INFO:
      return state.merge({
        methodInfo: fromJS(action.methodInfo),
        __id: action.__id,
        __type: action.__type,
      })

    //添加midtransUrl
    case actions.ADD_MIDTRANS_URL:
      return state.set('midtransUrl', action.midtransUrl)

    //清除midtransUrl
    case actions.REMOVE_MIDTRANS_URL:
      return state.set('midtransUrl', '')

    //记录当前选择的支付方式
    case actions.RECORD_CURRENT_METHOD:
      return state.set('curMethodInfo', action.curMethod)

    //填写信用卡号
    case actions.CHANGE_CARD_NUMBER:
      return state.setIn(['creditCardInfo', 'cardNumber'], action.cardNumber)

    //填写CVV号码
    case actions.CHANGE_CVV_NUMBER:
      return state.setIn(['creditCardInfo', 'cvvNumber'], action.cvvNumber)

    //填写过期年份
    case actions.CHANGE_EXPIRE_YEAR:
      return state.setIn(['creditCardInfo', 'expireYear'], action.expireYear)

    //填写过期月份
    case actions.CHANGE_EXPIRE_MONTH:
      return state.setIn(['creditCardInfo', 'expireMonth'], action.expireMonth)

    case actions.CHANGE_CREDIT_SUBMIT_STATUS:
      return state.setIn(['creditCardInfo', 'hasSubmitted'], action.status)

    case actions.SET_CARD_NUMBER_ERROR_TEXT:
      return state.setIn(
        ['creditCardInfo', 'cardNumberErrText'],
        action.errText,
      )

    case actions.SET_CARD_MM_ERROR_TEXT:
      return state.setIn(['creditCardInfo', 'cardMmErrText'], action.errText)

    case actions.SET_CARD_YY_ERROR_TEXT:
      return state.setIn(['creditCardInfo', 'cardYyErrText'], action.errText)

    case actions.SET_CARD_CVV_ERROR_TEXT:
      return state.setIn(['creditCardInfo', 'cardCvvErrText'], action.errText)

    //切换信用卡
    case actions.CHANGE_CREDIT_CARD:
      return state.merge({ ...action.cardObj })

    //添加便利店或者银行转账信息
    case actions.ADD_STORE_BANCKTRANSFER_INFO:
      return state.mergeIn(['storeOrBankTransferInfo'], {
        ...action.storeOrBankInfo,
      })

    //显示隐藏支付指引
    case actions.TOGGLE_PAYMENT_GUIDE:
      return state.setIn(
        ['storeOrBankTransferInfo', 'showPaymentGuide'],
        action.show,
      )
    default:
      return state
      break
  }
}

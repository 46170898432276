import { fromJS } from 'immutable'
import * as actions from './actions'

const defaultState = fromJS({
  // 是否被拒 1 --- 第一次授信，2 --- 被拒
  status: 1,
  // 职业 id
  occupationId: 0,
  stepOneData: {
    maritalStatusBlock: false,
    kidsNumerStatusBlock: false,
    yearsOfWorkBlock: false,
    monthlyIncomeBlock: false,
    educationBlock: false,
  },
  stepTwoData: {
    emergencyContactRelationshipBlock: false,
  },
  stepThreeData: {},
})

export default (state = defaultState, action) => {
  switch (action.type) {
    // 授信是否被拒
    case actions.SET_APPLY_STATUS:
      return state.merge({
        status: action.applyStatus,
      })
    // 职业 id
    case actions.SET_OCCUPATION_ID:
      return state.merge({
        occupationId: action.occupationId,
      })

    case actions.SET_ENTRYBLOCK_STATUS:
      if (action.step === '1') {
        return state.setIn(['stepOneData', action.key], action.status)
      } else if (action.step === '2') {
        return state.setIn(['stepTwoData', action.key], action.status)
      } else {
        return state.setIn(['stepThreeData', action.key], action.status)
      }

    case actions.SAVE_VERIFY_ENTRYID_LIST:
      if (action.step === '1') {
        return state.mergeDeep({
          stepOneData: {
            ...{
              verifyList: action.verifyList,
              authInfo: action.authInfo,
              entryIdList: action.entryIdList,
            },
          },
        })
      } else if (action.step === '2') {
        return state.mergeDeep({
          stepTwoData: {
            ...{
              verifyList: action.verifyList,
              authInfo: action.authInfo,
              entryIdList: action.entryIdList,
            },
          },
        })
      } else {
        return state.mergeDeep({
          stepThreeData: {
            ...{
              verifyList: action.verifyList,
              authInfo: action.authInfo,
              entryIdList: action.entryIdList,
            },
          },
        })
      }

    default:
      return state
  }
}

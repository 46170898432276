import { fromJS } from 'immutable'
import * as actions from './actions'
import { globalActions } from '@pc/common/globalActions'
import { COUPON_MODAL_FLAG } from '@pc/common/macro'

const defaultState = fromJS({
  plans: {},
  curPeriod: '0',
  showPlanDetails: false,

  showRiskCaptchaDialog: false, //风控短信验证码弹窗
  showLoginPasswordDialog: false, //登陆密码输入弹窗
  operationId: '', //风控短信验证的operationId
  sent: false, //标记风控验证码/下单验证码是否发送
  countDown: 60, //风控验证码/下单验证码倒计时
  captchaErrorText: '',
  loginPwdErrorText: '',
  riskCheckPass: false,

  downPayment: '0', //当前首付
  downpaymentRate: '0.0', //当前首付比例
  curPayment: '0', //当前待付款金额
  // fullPayOnly: false, //是否只能全款支付
  curPaymentStrategy: [], //当前首付比例的分期策略
  uid: '',
  insufficientLimit: false, //额度是否足够

  couponCode: '', //优惠码
  showCouponCodeForm: false,
  couponCodeErrText: '',
  couponList: [], // 可用的优惠券
  disabledCouponList: [], // 不可用的优惠券
  prevCouponId: '', // 打开优惠券弹窗的时候记录当前选中的 在关闭优惠券弹窗的时候 用来和当前的优惠券做比较 如果是不一致 需要重新更新分期信息
  curCouponId: '', // 当前选中的优惠券
  couponModalFlag: COUPON_MODAL_FLAG.COUPON, // 优惠券弹窗显示内容控制标志 1 显示优惠券  2 显示优惠券说明

  showDownPaymentRate: true, //部分0首付用户隐藏首付比例选择

  showPasswordAndCodeForm: false,

  showFullpayment: false, //默认隐藏全款选项
  // dpUserOptimize: false, //是否触发有首付选项优化（隐藏全款；首付比例选项改为手动触发半弹窗显示）

  socialSecurityData: false,

  basicDialog: {
    //提示框
    show: false,
    content: '',
  },
  basicPopup: {
    //slide up panel
    show: false,
    name: '',
  },
  securityVerificationVisible: false, // 安全组件弹窗
  needSecurityVerification: true, // 是否展示安全组件 接口返回的字段为 showSecurityVerification
  operationId: undefined, // 安全组件校验成功返回的operationId
  showSmallPayController: false, // 是否需要显示小额免密控件
  isSmallPay: false, // 是否是小额免密支付 只有需要展示小额免密控件的情况下值才有用
  quickPaymentInstructionVisible: false, // 是否显示小额免密的说明弹窗
  quickPaymentReminderVisible: false, // 是否显示小额免密的提示弹窗
  quickPaymentReminderHasOpen: false, // 是否打开过这个弹窗 如果出现过 就不会再打开了 除非从密码输入框里面打开
  showQuickPayTipInPasswordModal: false, // 控制密码输入框里面的小额免密的提示 输入密码成功之后 改为true，输入密码错误改为false
  showPromptModal: false, // 请求重复弹窗提示
  paymentId: '', // 支付id
  // 还款日 默认 25 号
  repaymentDay: 25,
  // 用户是否设置了还款日，true为已经设置，false为未设置
  userSetRepaymentDate: true,
  supportPaymentPassword: false, // 是否设置了支付密码
  showPayVerificationModal: false, // 是否弹出支付密码校验弹窗
  paymentPasswordOperationId: undefined, // 支付密码校验成功返回的操作id
  paymentPasswordCheckPass: false, // 是否通过支付密码校验
  commonInfo: {}, // common接口返回的一些配置数据
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_PREV_COUPON_ID:
      return state.set('prevCouponId', action.prevCouponId)

    case actions.SET_CUR_COUPON_ID:
      return state.set('curCouponId', action.curCouponId)

    case actions.SET_ORDER_DETAIL_BANNER:
      return state.set('orderDetailBanner', action.orderDetailBanner)

    case actions.SET_NEED_SOCIAL_SECURITY_FLAG:
      return state.merge({
        socialSecurityData: action.socialSecurityData,
      })

    //更新分期计划
    case actions.ADD_NEW_PLAN_TO_STATE:
      return state.merge({
        plans: action.orderPlan,
      })

    //改变验证码发送状态
    case actions.CHANGE_CODE_STATUS:
      return state.merge({
        sent: action.sent,
        countDown: 60,
      })

    //改变倒计时时间
    case actions.CHANGE_COUNTDOWN:
      return state.set('countDown', action.countDown)

    //恢复默认数据
    case globalActions.CLEAR_ORDER_DETAIL_DATA:
      return defaultState

    //设置短信验证码错误信息
    case actions.SET_CODE_ERROR_TEXT:
      return state.set('captchaErrorText', action.errText)

    //设置登录密码错误信息
    case actions.SET_LOGIN_PWD_ERROR_TEXT:
      return state.set('loginPwdErrorText', action.errText)

    //保存风控操作Id
    case actions.SAVE_OPERATION_ID:
      return state.set('operationId', action.operationId)

    //保存优惠码
    case actions.SET_COUPON_CODE:
      return state.set('couponCode', action.code)

    //设置优惠码错误信息
    case actions.SET_COUPON_ERR_TEXT:
      return state.set('couponCodeErrText', action.errText)

    //是否通过风控验证
    case actions.TOGGLE_RISK_CHECK_STATUS:
      return state.set('riskCheckPass', action.riskCheckPass)

    //登录后将新订单信息添加到state
    case actions.ADD_NEW_STATE_FROM_ORDER:
      return state.merge({ ...action.orderState })

    //设置全款支付
    case actions.SET_FULL_PAYMENT:
      return state.set('fullPayOnly', action.fullPayOnly)

    //设置首付比例
    case actions.SET_DOWNPAYMENT_RATE:
      return state.merge({
        downpaymentRate: action.downpaymentRate,
        curPaymentStrategy: action.curPaymentStrategy,
      })

    //选择分期数
    case actions.CHOOSE_PERIOD:
      return state.merge({
        curPeriod: action.period,
        curMonthlyPay: action.monthlyPay,
        downPayment: action.downPayment,
        curPayment: action.curPayment,
        lastMonthlyPay: action.lastMonthlyPay,
      })

    //显示/隐藏分期详情
    case actions.TOGGLE_PLAN_DETAILS:
      return state.set('showPlanDetails', action.show)

    //显示、隐藏弹窗
    case actions.TOGGLE_BASIC_DIALOG:
      return state.set('basicDialog', fromJS(action.basicDialog))

    //显示、隐藏优惠码
    case actions.TOGGLE_COUPONCODE_FORM:
      return state.set('showCouponCodeForm', action.show)

    //显示、隐藏短信验证码弹窗
    case actions.SHOW_RISK_CHAPTCHA_DIALOG:
      return state.set('showRiskCaptchaDialog', action.show)

    //显示、隐藏短信验证码弹窗
    case actions.SHOW_LOGIN_PASSWORD_DIALOG:
      return state.set('showLoginPasswordDialog', action.show)

    //显示、隐藏slideup panel
    case actions.TOGGLE_BASIC_POPUP:
      //关闭popup时不会传name字段
      if (!action.show) action.name = state.getIn(['basicPopup', 'name'])

      return state.set(
        'basicPopup',
        fromJS({
          show: action.show,
          name: action.name,
        }),
      )

    //保存点击下单按钮时间
    case actions.SAVE_CLICK_PAY_TIME:
      return state.set('clickPayTime', action.clickPayTime)

    //保存当前时间
    case actions.SAVE_ENTER_TIME:
      return state.set('enterTime', action.enterTime)

    //保存当前时间
    case actions.SAVE_PAGE_ID:
      return state.set('pageId', action.pageId)

    //保存订单日
    case actions.SET_BILL_DATE:
      return state.set('billDate', action.billDate)

    //保存订单日
    case actions.SET_INSUFFICIENT_LIMIT_FLAG:
      return state.set('insufficientLimit', action.insufficientLimit)

    case actions.SAVE_INTEREST_FREE_PERIOD:
      return state.set('periodOfInterestFree', action.period)

    case actions.TOGGLE_DOWNPAYMENT_RATE_DISPLAY:
      return state.set('showDownPaymentRate', action.show)

    case actions.TOGGLE_PWD_CODE_FORM:
      return state.set('showPasswordAndCodeForm', action.show)

    case actions.DISABLE_ORDER_BUTTON:
      return state.set('orderButtonDisabled', action.disabled)

    case actions.DP_USER_OPTIMIZE:
      return state.set('dpUserOptimize', action.dpUserOptimize)

    case actions.TOGGLE_FULL_PAYMENT:
      return state.set('showFullpayment', action.showFullpay)

    case actions.SET_COUPON_MODAL_FLAG:
      return state.set('couponModalFlag', action.couponModalFlag)

    case actions.SET_COUPON_LIST:
      const { availableCouponList = [], unavailableCouponList = [] } =
        action.coupons
      return state.merge({
        couponList: availableCouponList,
        disabledCouponList: unavailableCouponList,
      })

    case actions.TOGGLE_SECURITY_VERIFICATION:
      return state.set(
        'securityVerificationVisible',
        action.securityVerificationVisible,
      )

    case actions.SET_SHOW_SECURITY_VERIFICATION:
      return state.set(
        'needSecurityVerification',
        action.needSecurityVerification,
      )

    // 设置小额免密的值
    case actions.SET_IS_SMALL_PAY:
      return state.set('isSmallPay', action.isSmallPay)

    // 小额免密控件的展示
    case actions.SET_SHOW_SMALL_PAY_CONTROLLER:
      return state.set('showSmallPayController', action.showSmallPayController)

    case actions.TOGGLE_QUICK_PAYMENT_INSTRUCTION_VISIBLE:
      return state.set(
        'quickPaymentInstructionVisible',
        action.quickPaymentInstructionVisible,
      )

    case actions.TOGGLE_QUICK_PAYMENT_REMINDER_VISIBLE:
      return state.set(
        'quickPaymentReminderVisible',
        action.quickPaymentReminderVisible,
      )

    case actions.TOGGLE_QUICK_PAYMENT_REMINDER_HAS_OPEN:
      return state.set(
        'quickPaymentReminderHasOpen',
        action.quickPaymentReminderHasOpen,
      )

    case actions.SET_SHOW_QUICK_PAY_TIP_IN_PASSWORD_MODAL:
      return state.set(
        'showQuickPayTipInPasswordModal',
        action.showQuickPayTipInPasswordModal,
      )

    case actions.SET_SHOW_PROMPT_MODAL:
      return state.set('showPromptModal', action.showPromptModal)

    case actions.SET_SAVE_PAYMENT_ID:
      return state.set('paymentId', action.paymentId)

    case actions.SET_REPAYMENT_DAY:
      return state.set('repaymentDay', action.repaymentDay)

    // 设置 用户是否设置了支付密码
    case actions.SET_SUPPORT_PAYMENT_PWD:
      return state.set('supportPaymentPassword', action.supportPaymentPassword)
    // 显示、关闭支付密码校验弹窗
    case actions.SET_PAYMENT_VERIFICATION_VISIBLE:
      return state.set(
        'showPayVerificationModal',
        action.showPayVerificationModal,
      )
    // 设置支付密码校验ID
    case actions.SET_PAYMENT_VERIFICATION_ID:
      return state.set(
        'paymentPasswordOperationId',
        action.paymentPasswordOperationId,
      )

    //保存支付密码验证的参数和数据
    case actions.SET_PAYMENT_VERIFICATION_DATA:
      return state.merge({ ...action.paymentVerificationData })
    //保存common接口返回的配置信息
    case actions.SET_COMMON_INFO:
      return state.set('commonInfo', fromJS(action.data))

    default:
      return state
  }
}

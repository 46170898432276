// 需要脱敏加密的输入框数据
export const needMaskList = [
  { sn: 390001, cn: 3 }, // 短信登录手机号输入框
  { sn: 390001, cn: 5 }, // 短信登录验证码输入框
  { sn: 390001, cn: 9 }, // 密码登录手机号输入框
  { sn: 390001, cn: 10 }, // 密码登录密码输入框
  { sn: 390004, cn: 2 }, // 设置密码 输入密码
  { sn: 390004, cn: 3 }, // 设置密码 确认密码
  { sn: 390004, cn: 22 }, // 设置密码 确认密码
  { sn: 390010, cn: 2 }, // 授信流程 身份证全名
  { sn: 390011, cn: 3 }, // 授信流程 紧急联络人名字
  { sn: 390011, cn: 4 }, // 授信流程 紧急手机号码
  { sn: 390011, cn: 5 }, // 授信流程 电子邮件
]

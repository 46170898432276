import { getTracker } from './tracker'
import { helpers } from '@common'
import { needMaskList } from './needMaskList'
const appId = helpers.URL.getParam('appId')
const orderId = helpers.URL.getParam('refNo')
class Logger {
  currentScreenNum = null

  autoExpose = async () => {
    const tracker = await getTracker()
    // may be undefined when location path contains order-list
    tracker?.enableAutoExpose({
      behavior: {
        v4: {},
      },
    })
  }

  click = async (param) => {
    const tracker = await getTracker()
    if (!param.cn && !param.controlNum) {
      //controlNum是必须填写的 且不能为0
      param.cn = -1
    }

    tracker.click({
      behavior: {
        v4: {
          cid: '',
          bct: 0,
          ...param,
        },
      },
    })
  }

  input = async ({ key, cn, ic }) => {
    const tracker = await getTracker()
    const needMasking = needMaskList.some(
      (item) => item.sn === this.currentScreenNum && item.cn === cn,
    )
    tracker.input({
      behavior: {
        v4: {
          needMasking,
          // inputKey 必传，标记是否同一个 input
          inputKey: key,
          cn,
          ic,
          // 每次 input 产生的数据
        },
      },
    })
  }

  enter = async ({ screenNum, sn, sp, screenParam }) => {
    const tracker = await getTracker()
    const spConfig = screenParam || sp || {}
    this.setPageConfig({
      screenNum: screenNum || sn, //页面编码
      screenParam: { order_id: orderId, ...spConfig },
    })

    tracker.enter({
      behavior: {
        v4: {},
      },
    })
  }

  expose = async (param) => {
    const tracker = await getTracker()
    tracker.expose({
      behavior: {
        v4: {
          li: [
            {
              ...param,
              needPosInfo: false,
            },
          ],
        },
      },
    })
  }

  setPageConfig = async (param) => {
    const tracker = await getTracker()
    const screenNum = param.screenNum || param.sn
    if (screenNum) this.currentScreenNum = screenNum
    tracker.setPageConfig({
      behavior: {
        v4: {
          ...param,
          se: {
            channel_id: appId,
          },
        },
      },
    })
  }

  setUid = async (uid) => {
    const tracker = await getTracker()
    if (uid) {
      helpers.storage.setSession('openpay_uid', uid)
      tracker.updateSdkConfig({
        behavior: {
          v4: {
            userId: uid,
          },
        },
      })
    }
  }

  leave = async () => {
    const tracker = await getTracker()
    tracker.leave({
      behavior: {
        v4: {},
      },
    })
  }
}

const BizTracker = new Logger()

export default BizTracker

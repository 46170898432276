import { fromJS } from 'immutable'

import * as actions from './actions'

const defaultState = fromJS({
  password: '',
  phoneNumber: '',
  captcha: '',
  pwdErrorText: '', //设置密码错误提示信息
  sent: false, //标记验证码是否发送
  countDown: 60, //倒计时
  codeErrorText: '', //短信验证码错误信息
  graphicUrl: '', //图形验证码的地址（Base64格式）
  graphicErrorText: '', //图形验证码错误信息
  showGraphicDialog: false, //显示图形验证码弹框。命中后台规则的用户需要先输入图形验证码再获取短信验证码
  showSetPasswordDialog: false, //显示设置密码弹窗
  showRegisterSuccess: false, //显示注册成功
  showApplyInstruction: false,
  accessToken: '',
  securityVerificationTempParams: {}, // 安全组件调起时前置操作存储的临时参数，在每次关闭组件时清空，调起时保存
})

export default (state = defaultState, action) => {
  switch (action.type) {
    // 设置调起安全组件的前置保存参数
    case actions.SET_SECURITY_VERIFICATION_TEMP_PARAMS:
      return state.merge({
        securityVerificationTempParams: {
          ...action.securityVerificationTempParams,
        },
      })

    case actions.SET_ACCESS_TOKEN:
      return state.set('accessToken', action.accessToken)

    case actions.SET_CAPTCHA:
      return state.set('captcha', action.captcha)

    //设置短信验证码错误信息
    case actions.SET_CODE_ERROR_TEXT:
      return state.set('codeErrorText', action.errText)

    //设置手机号错误信息
    case actions.SET_PHONE_NUMBER_ERROR_TEXT:
      return state.set('phoneNumberErrorText', action.errText)

    //改变验证码发送状态
    case actions.CHANGE_CODE_STATUS:
      return state.merge({
        sent: action.sent,
        countDown: 60,
      })

    //改变倒计时时间
    case actions.CHANGE_COUNTDOWN:
      return state.set('countDown', action.countDown)

    //设置图形验证码错误信息
    case actions.SET_GRAPHIC_ERROR_TEXT:
      return state.set('graphicErrorText', action.errText)

    //显示/隐藏图形验证码弹框
    case actions.TOGGLE_GRAPHIC_DIALOG:
      return state.set('showGraphicDialog', action.show)

    //显示/隐藏设置密码弹框
    case actions.TOGGLE_SET_PASSWORD_DIALOG:
      return state.set('showSetPasswordDialog', action.show)

    //设置图形验证码URL
    case actions.SET_GRAPHIC_CAPTCHA_URL:
      return state.set('graphicUrl', action.url)

    //切换注册成功页面显示
    case actions.TOGGLE_REGISTER_SUCCESS:
      return state.set('showRegisterSuccess', action.show)

    //显示授信引导页
    case actions.SHOW_APPLY_INSTRUCTION:
      return state.set('showApplyInstruction', action.show)

    case actions.SAVE_PHONE_NUMBER:
      return state.set('phoneNumber', action.phoneNumber)

    case actions.SET_PASSWORD_ERROR_TEXT:
      return state.set('pwdErrorText', action.errText)

    default:
      return state
  }
}
